@import url("https://fonts.googleapis.com/css2?family=Exo+2:wght@300;600;700&family=Open+Sans:wght@400;700&display=swap");

:root {
  --digilab: #162448;
  --ocean: #009fe3;
  --spring: #7db928;
  --sunflower: #ffb500;
  --tidal: #6ee7b7;
  --good: #ACEDAB60;
  --bad: #EB6E7360;
  --charcoal: #1d1d1b;
  --ash: #b6b6af;
  --beaker: 50px;
  --overlay: linear-gradient(to top, rgba(0, 9, 43, 0.5), rgba(0, 0, 0, 0));
  --box-shadow: 2px 4px 4px #00000030;
  --pattern-width: 600px;
}

/* I. General Format */
h1  {
  color: var(--charcoal);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  margin-bottom: 20px;
}



.twinlab{
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
}


h1 {
    font-size: 60px;
    background: -webkit-linear-gradient(var(--ash), #FFFFFF);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: 700;
    margin-bottom: 30px;
  }

.homebox {
    background-color: white;
    /* background-image: url("assets/background.svg"); */
    background-size: cover; 
    min-height: calc(100vh - 96px);
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    }

/* II. Login page */

.loginButton {
  margin: 10px;
}

/* .loginButton:hover {
  background-color: var(--charcoal);
} */

.signLink {
  font-weight: bolder;
}


.dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); 
  text-align: center;
  max-width: 90%; 
  width: 500px; 
  z-index: 1001; 
}

.dialog-content button {
  margin: 10px;
}

/* II. Terms and conditions page */

.center-container{
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  z-index: 1;
}

.terms {
    padding: 20px;
    height: calc(100vh - 200px);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    margin-top: 20px;
    color: grey;
    }

.conditionsBox {
      color: white;
      padding: 30px;
      overflow-y: auto;
      max-height: 180px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      border: 1px solid white;
      /* border-radius: 10px; */
    }

.conditionsBox h3 {
      color: white;
      font-family: "Exo 2", source-code-pro, monospace;
      font-weight: 600;
      size: 20px;
      margin-bottom: 20px;
    }

.checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; 
  color: white;
}


.buttons button {
      color: white;
      background-color: black;
      border-color: white;
      flex-grow: 1;
      margin-top: 7px ;
      margin-left: 5px;
      margin-right: 5px;
    }

/* III. Contact page */

.welcomeheader {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  margin-bottom: 20px;
}

.buttonuser {
  margin: 20px;
  background-color: var(--digilab);
  color: white;
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
}

.link {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  text-decoration: 'none';
}

.link:hover {
  font-weight: 700; /* Increase font-weight when hovering */
}


/* III. Api key page */

.welcomeheader {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  margin-bottom: 20px;
}

.welcomeheader2 {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 400;
  margin-bottom: 20px;
}

.apikey {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: bold;
  margin-top: 0px;
  box-shadow: 0 8px 16px rgba(22, 36, 72, 0.2); 
  padding: 10px; 
  border: 0.5px solid #162448; 
  display: inline-block; /* Ensures the box size fits its content */
  white-space: nowrap; /* Prevents the text from wrapping */
  justify-content: center;
  align-items: center;
  gap: 5px; /* Optional: Adds space between the text and the button */
}

.iconcoppy {
  color: var(--digilab);
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
  margin-bottom: 7px;

}

.copyapibutton {
  background-color: black;
  color: white;
  border-width: 2px;
  border-color: white;
}

.buttondiv {
  margin-top: 20px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
}

.buttonuser {
  margin: 20px;
  background-color: var(--digilab);
  color: white;
  font-family: "Exo 2", source-code-pro, monospace;
  font-weight: 600;
}

/* IV. Sign up page */

.formfield {
  width: 300px;
  color: var(--charcoal);
}

.formfield div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 15px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 4px;
  border-color: white;
  cursor: pointer;
}

button:hover {
  transform: scale(1.1);
}

.center-containersignup{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-weight: bold;
  color: var(--charcoal);
}

.contact-section {
  text-align: center;
  margin-top: 50px;
}

.contact-info {
  margin-top: 20px;
}

.contact-info a {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  margin-right: 10px;
}

/* Fluent UI icon styles */
.ms-Icon {
  font-size: 20px;
}

.loader {
  padding: 300px;
  height: 100%;
  /* margin: 150px; */
  margin: 10%;
}

.homebox2 {
  background-color: white;
  /* background-image: url("assets/background.svg"); */
  background-size: cover; 
  /* min-height: calc(100vh - 96px); */
  height: 100%;
  width: 100%;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  }

/* Cognito styling */

[data-amplify-authenticator] {
  /* Shadow */
  --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10); 
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: var(--digilab);
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--digilab);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
  --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-active-color: var(--digilab);
  --amplify-components-button-link-color: var(--digilab);
  --amplify-components-button-link-hover-color: var(--digilab);
}


.box-api-key{
  /* background-color: var(--good);
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px; */
  color: var(--digilab);
}