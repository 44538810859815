html, body {
  margin: 0;
  padding: 0;
  height: 110%;
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

.App {
  text-align: center;
  background-color: white;
  /* background-image: url("assets/background.svg"); This was the previous background desinged by Deene */ 
  background-size: cover; 
}